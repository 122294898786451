import { makeObservable, observable, runInAction } from "mobx";
import instance from "./instance";

class LoyalityStore {
  loyalities = [];
  loading = false;
  constructor() {
    makeObservable(this, {
      loyalities: observable,
      loading: observable,
    });
  }

  fetchLoyalitys = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await instance.post("/invoices/my-loyality");
      runInAction(() => {
        this.loyalities = res.data;
        this.loading = false;
      });
    } catch (error) {
      console.error(error);
    }
  };
}

const lotyalityStore = new LoyalityStore();
// lotyalityStore.fetchLoyalitys();
export default lotyalityStore;
