import { makeAutoObservable, runInAction } from "mobx";
import instance from "./instance";
import decode from "jwt-decode";
import lotyalityStore from "./loyalityStore";
import { toast } from "react-toastify";
import addressStore from "./addressStore";

class AuthStore {
  loading = true;
  /* Assign user */
  user = null;
  guestUser = null;
  welcome = true;
  constructor() {
    makeAutoObservable(this);
  }

  /* Create New Trainee */
  register = async (newUser) => {
    try {
      const res = await instance.post("/signup", {
        ...newUser,
        user_type: "customer",
      });
      toast.error(res.data.message);
      this.setUser(res.data.token);
    } catch (error) {
      console.error(error); // error message
    }
  };

  /* Login Trainee */
  login = async (userData) => {
    try {
      const res = await instance.post("/user/signin", userData);
      this.setUser(res.data.token);
      lotyalityStore.fetchLoyalitys();
      addressStore.fetchAddresses();
      return true;
    } catch (error) {
      toast.error("Username or password is wrong");

      console.error(error);
      return false;
    }
  };

  /* Logout Trainee */
  logout = (user) => {
    if (user === "user") {
      delete instance.defaults.headers.common.Authorization;
      localStorage.removeItem("userNewToken");
      this.user = null;
    } else {
      delete instance.defaults.headers.common.Authorization;
      localStorage.removeItem("guestToken");
      this.guestUser = null;
    }
    this.checkForToken();
  };

  /* Set Trainee token in local storage */
  setUser = async (token) => {
    delete instance.defaults.headers.common.Authorization;
    localStorage.setItem("userNewToken", token);
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    runInAction(() => (this.user = decode(token)));
  };
  setGuestuser = async (token) => {
    localStorage.setItem("guestToken", token);
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    runInAction(() => (this.guestUser = decode(token)));
  };
  findBranch = async () => {
    const res = await instance.get("/branches/branch");
    runInAction(() => (this.branch = res.data));
  };
  /* Check the Trainee Token */
  checkForToken = async () => {
    delete instance.defaults.headers.common.Authorization;
    // localStorage.removeItem("userNewToken");
    localStorage.removeItem("userToken");
    const token = localStorage.getItem("userNewToken");
    if (token) {
      // delete instance.defaults.headers.common.Authorization;
      // instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      //const currentTime = Date.now();
      const user = decode(token);
      if (user) {
        this.setUser(token);
      } else {
        this.logout("user");
      }
      this.loading = false;
    } else {
      const GuestToken =
        "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwidXNlcm5hbWUiOiJwZW9ueS1zcyIsImVtYWlsIjpudWxsLCJ1c2VyX3R5cGUiOiJicmFuY2gifQ.RxaLuVTeUzWslDq3oAUqOip029lvR-ls5A6xU1IO8do";
      // instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (GuestToken) {
        //const currentTime = Date.now();
        const Guestuser = decode(GuestToken);
        if (Guestuser) {
          this.setGuestuser(GuestToken);
        } else {
          this.logout("guest");
        }
      }

      this.loading = false;
    }
  };
  skipWelcome = () => {
    const date = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-GB", options);
    this.welcome = false;
    localStorage.setItem("skipWelcome", formattedDate);
  };
  checkSkipWelcome = () => {
    const date = new Date();
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-GB", options);
    const skiped = localStorage.getItem("skipWelcome");
    if (skiped === formattedDate) {
      this.welcome = false;
    }
  };
  /* Forget User Password */
  forgotPass = async (userName) => {
    try {
      await instance.post("/forgot-password", userName);
    } catch (error) {
      console.error(error);
    }
  };
  /* Change User Password */
  changePass = async (profile) => {
    try {
      const res = await instance.put("/change-password", profile);
      if (res.data.status === "success") {
        window.location.href = "/signin";
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* Change User Eamil */
  changeEmail = async (body) => {
    try {
      const res = await instance.put("/change-email", body);
      this.user = { ...this.user, email: body.email };
      this.setUser(res.data.token);
    } catch (error) {
      console.error(error);
    }
  };
}
const authStore = new AuthStore(); // create instance
authStore.checkForToken();
authStore.checkSkipWelcome();
lotyalityStore.fetchLoyalitys();
addressStore.fetchAddresses();

export default authStore; // export it
