import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab, Spinner } from "react-bootstrap";
import CartItem from "./CartItem";
import invoiceStore from "./stores/cartStore";
import { toast } from "react-toastify";
// import image from "./cartImage.jpeg";
import { Link } from "react-router-dom";
import { BsWhatsapp, BsTiktok } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import Appointment from "./Appointment";
import instance from "./stores/instance";
import { Card } from "@mui/material";
import addressStore from "./stores/addressStore";
import AddressItem from "./AddressItem";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import authStore from "./stores/authStore";

const CartList = ({ setPath }) => {
  const [phone_number, setPhone] = useState(
    authStore.user?.profile?.phone_number.toString() ?? ""
  );
  const [validated, setValidated] = useState(false);
  // const [homeservice, setHomeService] = useState(false);
  const history = useHistory();
  const today = new Date();

  const localDate = today.toLocaleDateString("en-US", {
    timeZone: "Europe/Moscow",
  });
  const parts = localDate.split("/");
  const formattedDate = `${parts[2]}-${parts[0].padStart(
    2,
    "0"
  )}-${parts[1].padStart(2, "0")}`;
  const [date, setDate] = useState(formattedDate);

  const cartList = invoiceStore.items.map((item, index) => (
    <CartItem item={item} key={index} index={index + 1} />
  ));

  useEffect(() => {
    if (history.location.state === "address") {
      // setHomeService(true);
      invoiceStore.setHomeService(true);
    }

    if (invoiceStore.date === "") {
      invoiceStore.setDate(date);
    } else {
      setDate(invoiceStore.date);
    }
    const checkServices = async () => {
      const check = await instance.post("/invoices/loyality", {
        items: invoiceStore.items,
      });
      if (check.data.list) {
        for (let i of check.data.list) {
          const item = invoiceStore.items.find(
            (e) => e.serviceId === i.serviceId
          );
          if (item && item.free) {
            item.price = 0;
          }
        }
      }
    };

    checkServices();
  }, [history.location.state, date]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const handleCheckout = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    const checkAddress = invoiceStore.checkAddress();
    // if (!phone_number || phone_number.length !== 8) {
    //   toast.warn("الرجاء اضافة رقم الهاتف");
    // } else
    if (invoiceStore.slot === "") {
      toast.warn("الرجاء اختيار الوقت");
    } else if (
      invoiceStore.homeservice === true &&
      invoiceStore.totalPrice < 10
    ) {
      toast.error("الحد الادنى 10 د.ك");
    } else if (checkAddress === false) {
      return;
    } else {
      const phone = parseInt(phone_number);
      invoiceStore.checkout(phone, "online", formatDate(date), false);
      // setPhone();
    }
  };
  // const CartBottom = () => (

  // );

  const handleTabSelect = (selectedTab) => {
    if (selectedTab === "tab2") {
      invoiceStore.setHomeService(true);
      // setHomeService(true);
    } else {
      invoiceStore.setHomeService(false);
      // setHomeService(false);
    }
    setDate(invoiceStore.date);
  };

  if (invoiceStore.loading)
    return (
      <div className="fixedCategories">
        <Spinner />
      </div>
    );
  // if (invoiceStore.loading) {
  //   setHomeService(invoiceStore.invoiceStore.homeservice);
  // }
  return (
    <div className="fixedCategories" style={{ overflowY: "hidden" }}>
      {cartList.length === 0 ? (
        <div
          className="bottomCart"
          style={{ height: "100vh", zIndex: 1000, padding: 0 }}>
          {/* Your existing content for Tab 1 */}
          <button
            style={{
              position: "absolute",
              bottom: 300,
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
            }}>
            <Link to="/" style={{ color: "white" }}>
              ابدا التسوق
            </Link>
            <div
              style={{
                position: "absolute",
                bottom: 140,
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
              }}>
              <a href="https://www.tiktok.com/@peonykw?_t=8ghlvceZT50&_r=1">
                {" "}
                <BsTiktok size="25" style={{ margin: 10 }} color={"#c39e81"} />
              </a>
              <a href="https://wa.me/96550124997">
                {" "}
                <BsWhatsapp
                  size="25"
                  style={{ margin: 10 }}
                  color={"#c39e81"}
                />
              </a>
              <a href="https://instagram.com/peonyloungespa">
                <AiOutlineInstagram
                  size="30"
                  style={{ margin: 10 }}
                  color={"#c39e81"}
                />
              </a>
            </div>
          </button>
          {/* <img
            src={image}
            style={{ width: "100%", height: "100vh" }}
            alt="peony"
          /> */}
        </div>
      ) : (
        <Card
          elevation={5}
          style={{
            marginRight: 10,
            marginLeft: 10,
            borderRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}>
          <Tabs
            defaultActiveKey={invoiceStore.defaultTab}
            id="cart-tabs"
            onSelect={handleTabSelect}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Tab eventKey="tab1" title="داخل الصالون" className="tab1">
              <>
                <div
                  style={{
                    display: "inline-block",
                    // height: "85vh",
                    overflowY: "scroll",
                    width: "100%",
                    paddingTop: 10,
                    paddingBottom: 30,
                  }}>
                  <Appointment
                    date={invoiceStore.date}
                    formattedDate={formattedDate}
                    setDate={setDate}
                    homeservice={invoiceStore.homeservice}
                  />
                  <div style={{ minHeight: "41%" }}>{cartList}</div>
                  <div className="bottomCart" style={{ height: 300 }}>
                    <Form validated={validated}>
                      <div>
                        <div style={{ margin: 15 }}>
                          {/* <label style={{ marginLeft: 5 }}>رقم الهاتف</label> */}
                          <Form.Control
                            required
                            type="text"
                            placeholder="رقم الهاتف"
                            value={phone_number}
                            onChange={(e) =>
                              (e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1")) &
                              setPhone(e.target.value)
                            }
                          />
                        </div>
                        <div style={{ paddingRight: 15 }}>
                          <Form.Check
                            style={{ fontSize: 14 }}
                            reverse
                            required
                            type={"checkbox"}
                            id={`checkbox`}
                            label={`يخصم عربون بقيمة 1 د.ك عند الغاء اي عمل بالفاتورة، تطبق الشروط والاحكام`}
                          />
                        </div>
                        {/* <div style={{ paddingRight: 15 }}>
                        <Form.Check
                          style={{ fontSize: 14 }}
                          reverse
                          required
                          type={"checkbox"}
                          id={`checkbox`}
                          label={"الحجز لشخص واحد فقط تجنبا لالغاء الموعد"}
                        />
                      </div> */}
                      </div>
                      <div className="total">
                        <p style={{ fontSize: 20, margin: 0 }}>المجموع:</p>
                        <p style={{ fontSize: 20, margin: 0 }}>
                          KWD {invoiceStore.totalPrice.toFixed(2)}
                        </p>
                      </div>
                      <button
                        onClick={handleCheckout}
                        style={{ width: "90%", padding: 5 }}>
                        الدفع
                      </button>
                    </Form>
                  </div>
                </div>
              </>
            </Tab>
            <Tab eventKey="tab2" title="----" className="tab2" disabled>
              <>
                <div
                  style={{
                    display: "inline-block",
                    // height: "100vh",
                    overflowY: "scroll",
                    width: "100%",
                    paddingTop: 10,
                    paddingBottom: 50,
                  }}>
                  <Appointment
                    date={invoiceStore.date}
                    formattedDate={formattedDate}
                    setDate={setDate}
                    homeservice={invoiceStore.homeservice}
                  />
                  <div>{cartList}</div>
                  <div style={{ padding: 10 }}>
                    {" "}
                    {addressStore.defaultAddress ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "90%",
                          }}>
                          <p>العنوان</p>
                          <Button
                            onClick={() =>
                              history.push({
                                pathname: "/address",
                                state: "cart",
                              })
                            }
                            variant="none"
                            style={{ width: 20, fontSize: 12, color: "blue" }}>
                            تغيير
                          </Button>
                        </div>
                        <AddressItem
                          address={addressStore.defaultAddress}
                          onClick={null}
                        />
                      </div>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          marginTop: 23,
                          backgroundColor: "#c39e81",
                          borderColor: "#c39e81",
                        }}
                        // variant={"outline-danger"}
                        onClick={() =>
                          history.push({
                            pathname: "/address",
                            state: "cart",
                          })
                        }>
                        اضافه عنوان
                      </Button>
                    )}
                  </div>
                  <div className="bottomCart" style={{ height: 300 }}>
                    <Form validated={validated}>
                      <div>
                        <div style={{ margin: 15 }}>
                          {/* <label style={{ marginLeft: 5 }}>رقم الهاتف</label> */}
                          <Form.Control
                            required
                            type="text"
                            placeholder="رقم الهاتف"
                            value={phone_number}
                            onChange={(e) =>
                              (e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1")) &
                              setPhone(e.target.value)
                            }
                          />
                        </div>
                        <div style={{ paddingRight: 15 }}>
                          <Form.Check
                            style={{ fontSize: 14 }}
                            reverse
                            required
                            type={"checkbox"}
                            id={`checkbox`}
                            label={`يخصم عربون بقيمة 1 د.ك عند الغاء اي عمل بالفاتورة، تطبق الشروط والاحكام`}
                          />
                        </div>
                        {/* <div style={{ paddingRight: 15 }}>
                        <Form.Check
                          style={{ fontSize: 14 }}
                          reverse
                          required
                          type={"checkbox"}
                          id={`checkbox`}
                          label={"الحجز لشخص واحد فقط تجنبا لالغاء الموعد"}
                        />
                      </div> */}
                        <div style={{ paddingRight: 15 }}>
                          <Form.Check
                            style={{ fontSize: 14 }}
                            reverse
                            checked
                            readOnly
                            required
                            type={"checkbox"}
                            id={`checkbox`}
                            label={"الحد الادنى 10 د.ك"}
                          />
                        </div>
                        <div style={{ paddingRight: 15 }}>
                          <Form.Check
                            style={{ fontSize: 14 }}
                            reverse
                            required
                            readOnly
                            checked
                            type={"checkbox"}
                            id={`checkbox`}
                            label={
                              "التوصيل ٣.٥ د.ك غير مسترجع في حال تم التوصيل"
                            }
                          />
                        </div>
                      </div>
                      <div className="total">
                        <p style={{ fontSize: 20, margin: 0 }}>المجموع:</p>
                        <p style={{ fontSize: 20, margin: 0 }}>
                          KWD {invoiceStore.totalPrice.toFixed(2)}
                        </p>
                      </div>
                      <button
                        onClick={handleCheckout}
                        style={{ width: "90%", padding: 5 }}>
                        الدفع
                      </button>
                    </Form>
                  </div>
                </div>
              </>
            </Tab>
          </Tabs>
        </Card>
      )}
    </div>
  );
};

export default observer(CartList);
