// import { Link } from "react-router-dom";
// import { VscPass } from "react-icons/vsc";
import { useEffect } from "react";
import invoiceStore from "./stores/cartStore";
import { Col, Row, Spinner } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";

const Successful = () => {
  useEffect(() => {
    invoiceStore.clearCArt();
  }, []);
  if (invoiceStore.loading) return <Spinner />;
  const info = window.location.search
    .split(/&/)
    .includes("??paymentFor=SUBSCRIPTION")
    ? window.location.search.split(/&/).slice(1)
    : window.location.search.split(/&/);
  const productsList = invoiceStore.order.order.map((product, i) => (
    <li key={i}>
      <div className="d-flex align-items-center align-content-center ">
        <p className="details-title">
          {/* <img
            src={product.image}
            alt={product.name}
            className="order-details-img"
          / */}
          {/* {i18n.language === "ar" ? product.name_ar : product.name}x */}
          {product.quantity}X{product.name_ar}
        </p>
        <p className="details">{product.price} KWD</p>
      </div>
    </li>
  ));
  const convert = (time) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  return (
    <>
      <div
        style={{
          direction: "ltr",
          height: "100vh",
          marginTop: 100,
          marginBottom: 100,
        }}
        className=" p-md-0 main-padding p-2  pt-5 d-flex justify-content-center align-items-center"
        // style={{ backgroundColor: "#edece8" }}
      >
        <Col xs={12} md={5}>
          <Row className="justify-content-center">
            <AiOutlineCheckCircle
              color={"green"}
              size={50}
              className="text-center"
            />
          </Row>
          <h2 className="text-center">Thank You!</h2>
          {/* <p className="m-0  text-center font-size-15-weight500">
            {placedOrder}
          </p> */}
          {/* <p className="m-0 mb-5 text-center font-size-15-weight500">
            {confirmEmailSent}
          </p> */}
          <div
            className="payment-details-box order-details-box"
            style={{ paddingBottom: 0, marginBottom: 10 }}>
            <div className="d-flex justify-content-center">
              <p style={{ marginRight: 15, fontWeight: 700 }}>
                {" "}
                {invoiceStore.order.appointmentDate}
              </p>
              <p style={{ fontWeight: 700 }}>موعدج بتاريخ</p>
            </div>
            <div className="d-flex justify-content-center">
              <p style={{ marginRight: 15, fontWeight: 700 }}>
                {" "}
                {convert(invoiceStore.order.time)}
              </p>
              <p style={{ fontWeight: 700 }}>الساعه</p>
            </div>
          </div>
          <div className="payment-details-box order-details-box">
            <ul>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Order Number: </p>
                  <p className="details"> {invoiceStore.order.invoiceId}</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Payment ID:</p>
                  <p className="details"> {info[0].split("=")[1]}</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Refrence ID:</p>
                  <p className="details"> {info[4].split("=")[1]}</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Result</p>
                  <p className="details"> {info[1].split("=")[1]}</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Date : </p>
                  <p className="details"> {invoiceStore.order.date}</p>
                </div>
              </li>

              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">total : </p>
                  <p className="details"> {invoiceStore.order.price} KWD</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center align-content-center ">
                  <p className="details-title">Payment Method : </p>
                  <p className="details"> {invoiceStore.order.payment}</p>
                </div>
              </li>
            </ul>
          </div>
          <p className="m-4 text-center">Order Details </p>
          <div className=" payment-details-box order-details-box">
            <ul>
              <li>
                <div className="d-flex border-bottom">
                  <p className="details-title">Products </p>
                  <p className="details"> total</p>
                </div>
              </li>
              {productsList}
              <li>
                <div className="d-flex">
                  <p className="details-title">sub price: </p>
                  <p className="details">{invoiceStore.order.price} KWD</p>
                </div>
              </li>

              <li>
                <div className="d-flex border-bottom">
                  <p className="details-title">Grand Total: </p>
                  <p className="details">{invoiceStore.order.price} KWD</p>
                </div>
              </li>
            </ul>
          </div>
        </Col>
      </div>
    </>
  );
};

export default Successful;
