import React from "react";
import { Redirect } from "react-router-dom";
import cartStore from "./stores/cartStore";

function Checkout() {
  const [address, setAddress] = React.useState({
    house: "",
    phone: 0,
    ave: "",
    street: "",
    area: "",
    block: "",
  });
  console.log(address);
  const _setAddress = (event) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };
  const _setPhone = (event) => {
    setAddress({
      ...address,
      [event.target.name]: parseInt(event.target.value),
    });
  };
  const checkout = (event) => {
    event.preventDefault();
    cartStore.checkout(address, "cash");
  };
  if (cartStore.totalPrice < 5) return <Redirect to={"/"} />;
  return (
    <div className="fixedCategories" style={{ width: "100%" }}>
      {/* <div className="listContaienr"  */}
      <form onSubmit={checkout}>
        <input
          velue={address.area}
          name="area"
          required
          onChange={_setAddress}
          placeholder="المنطقه"
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        <input
          velue={address.street}
          name="street"
          required
          onChange={_setAddress}
          placeholder="الشارع"
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        <input
          velue={address.block}
          name="block"
          required
          onChange={_setAddress}
          placeholder="القطعه"
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        <input
          velue={address.ave}
          name="ave"
          placeholder="الجاده"
          onChange={_setAddress}
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        <input
          velue={address.house}
          name="house"
          required
          onChange={_setAddress}
          placeholder="المنزل"
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        <input
          velue={address.phone}
          name="phone"
          required={true}
          onChange={_setPhone}
          placeholder="رقم الهاتف"
          style={{
            marginTop: 10,
            width: "80%",
            height: 50,
            fontSize: 20,
            textAlign: "right",
            paddingRight: 5,
            margin: 5,
          }}
        />
        {/* </div> */}

        <p>المجموع : {cartStore.totalPrice} دك</p>
        <p>التوصيل : {(1).toFixed(2)} دك</p>
        <p>المجموع الكلي : {parseFloat(cartStore.totalPrice) + 1} دك</p>

        <button
          style={{
            position: "fixed",
            right: 10,
            width: "95%",
            background: "black",
            height: 50,
            color: "white",
            borderColor: "black",
            fontSize: 20,
            fontWeight: "bold",
            // borderTopRightRadius: 20,
            // borderBottomRightRadius: 20,
            borderRadius: 20,
            bottom: 15,
          }}
          // onClick={() => history.push("/order-detail")}
        >
          Cash
        </button>
        {/* <button
          style={{
            position: "fixed",
            left: 3,
            width: "50%",
            background: "black",
            height: 50,
            color: "white",
            borderColor: "black",
            fontSize: 20,
            fontWeight: "bold",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            bottom: 5,
          }}
        >
          K-net
        </button> */}
      </form>
    </div>
  );
}

export default Checkout;
