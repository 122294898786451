import { observer } from "mobx-react";
import React from "react";
import OrderItem from "./OrderItem";
import cartStore from "./stores/cartStore";

function Order({ order }) {
  const orderItems = order.newOrders.map((product) => (
    <OrderItem product={product} />
  ));

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button
              style={{
                width: 90,
                height: 40,
                backgroundColor: order.done ? "tomato" : "gray",
              }}
              onClick={() => cartStore.updateOrder(order.id)}
            >
              {" "}
              تم{" "}
            </button>
          </div>
          <div
            style={{
              width: "30%",
              borderBottomWidth: 0.5,
              borderBottomColor: "black",
              borderBottom: "solid",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                fontSize: 12,
                width: "50%",
              }}
            >
              رقم الطلب {order.id}
            </p>{" "}
            <p
              style={{
                fontSize: 12,
                width: "50%",
              }}
            >
              دك {order.price}
            </p>{" "}
          </div>
          الطلبات
          {orderItems}
        </div>
        <div style={{ width: "50%" }}>
          <p>العنوان </p>
          <p>المنطقه {order.area}</p>
          <p>القطعه {order.block}</p>
          <p>الشاع {order.street}</p>
          <p>المنزل {order.house}</p>
          <p>الجاده {order.ave}</p>
          <p>الهاتف {order.phone}</p>
        </div>
      </div>
      <div style={{ height: 1, width: "100%", backgroundColor: "black" }}></div>
    </>
  );
}

export default observer(Order);
