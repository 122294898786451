import React from "react";

// import { FiGift } from "react-icons/fi";
import authStore from "./stores/authStore";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
// import { Button } from "@mui/base";
import { Button } from "react-bootstrap";

function Profile() {
  if (!authStore.user) return <Redirect to="/signin" />;

  return (
    <div className="profile">
      {/* <Card sx={{ minWidth: 275 }} style={{ marginBottom: 10 }}>
        <CardContent> */}
      {/* <div className="profile_field">
        <p className="title">الاسم: </p>{" "}
        <input defaultValue={authStore.user.profile?.name} readOnly />
      </div> */}
      <div className="profile_field">
        <p className="title">اسم المستخدم: </p>
        <input defaultValue={authStore.user.username} readOnly />
      </div>
      <div className="profile_field">
        <p className="title">رقم الهاتف: </p>{" "}
        <input defaultValue={authStore.user.profile?.phone_number} readOnly />
      </div>
      <div className="profile_field">
        <p className="title">الايميل: </p>{" "}
        <input defaultValue={authStore.user.email} readOnly />
      </div>

      <div className="profile_field">
        <p className="title">يوم الميلاد: </p>{" "}
        <input
          defaultValue={authStore.user.profile?.date_of_birth}
          type="date"
          readOnly
        />
      </div>
      {/* </CardContent>
      </Card> */}

      <Button
        style={{ width: "100%", marginTop: 23 }}
        variant={"outline-danger"}
        onClick={() => authStore.logout("user")}>
        تسجيل الخروج
      </Button>
    </div>
  );
}

export default observer(Profile);
