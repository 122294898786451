import { observer } from "mobx-react";
import React from "react";
import invoiceStore from "./stores/cartStore";
// import { BsTrash } from "react-icons/bs";

const CartItem = ({ item, index }) => {
  // const handleDelete = () => {
  //   invoiceStore.removeItemFromInvoice(item.itemId);
  // };
  return (
    <div className="product" style={{ paddingTop: 15 }}>
      <div style={{ width: "70%", textAlign: "right" }}>
        <div
          style={{
            display: "flex",
            alignContent: "start",
            alignItems: "start",
          }}>
          <p style={{ marginLeft: 5 }}>{index} .</p>
          <div>
            <p
              className="name"
              style={{
                textAlign: "right",
                margin: 0,
                padding: 0,
                width: "100%",
              }}>
              {item.name_ar}
            </p>
            <p>{item.price.toFixed(2)} KD </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: 60,
          justifyContent: "space-between",
        }}>
        {/* <BsTrash color={"red"} onClick={handleDelete} /> */}

        <div
          style={{
            width: "80%",
            height: 30,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            backgroundColor: "#c39e81",
            borderRadius: 50,
            // marginTop: 20,
            // marginBottom: -20,
            // marginLeft: 80,
          }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "white",
              height: "100%",
              lineHeight: 1.4,
              width: "30%",
              margin: 0,

              borderTopRightRadius: 25,
              borderBottomRightRadius: 25,
            }}
            onClick={() => invoiceStore.quantityItem(item)}>
            -
          </p>
          <p
            style={{
              fontSize: 17,
              color: "white",
              margin: 0,
              fontWeight: "bold",
            }}>
            {item.quantity}
          </p>
          <p
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "white",
              height: "100%",
              margin: 0,
              width: "30%",
              lineHeight: 1.4,
              borderTopLeftRadius: 25,
              borderBottomLeftRadius: 25,
            }}
            onClick={() =>
              item.type === "package"
                ? null
                : invoiceStore.addItemToInvoice(item)
            }>
            +
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(CartItem);
