import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Order from "./Order";
import cartStore from "./stores/cartStore";

function Admin() {
  const [orders, setOrders] = React.useState("today");
  useEffect(() => {
    const interval = setInterval(() => {
      cartStore.fetchInvoices();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  let dtFormat = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const todayOrders = cartStore.orders
    .filter(
      (order) =>
        dtFormat.format(new Date(order.createdAt)) ===
          dtFormat.format(new Date()) && order.done === false
    )
    .slice()
    .sort((a, b) => b.id - a.id)
    .map((order) => <Order order={order} key={order.id} />);

  const allOrders = cartStore.orders
    .slice()
    .sort((a, b) => b.id - a.id)
    .map((order) => <Order order={order} key={order.id} />);

  const todayDoneOrders = cartStore.orders
    .filter(
      (order) =>
        dtFormat.format(new Date(order.createdAt)) ===
          dtFormat.format(new Date()) && order.done === true
    )
    .slice()
    .sort((a, b) => b.id - a.id)
    .map((order) => <Order order={order} key={order.id} />);
  if (cartStore.loading === true)
    return (
      <div className="fixedCategories">
        <div className="listContaienr">Loading ...</div>
      </div>
    );
  return (
    <div className="fixedCategories">
      <div>
        <button
          onClick={() => setOrders("today")}
          style={{
            backgroundColor: orders === "today" ? "green" : null,
            height: 40,
            fontSize: 19,
          }}
        >
          اليوم
        </button>
        <button
          onClick={() => setOrders("done today")}
          style={{
            backgroundColor: orders === "done today" ? "green" : null,
            height: 40,
            fontSize: 19,
          }}
        >
          تم التوصيل اليوم
        </button>
        <button
          onClick={() => setOrders("all")}
          style={{
            backgroundColor: orders === "all" ? "green" : null,
            height: 40,
            fontSize: 19,
          }}
        >
          الكل
        </button>
      </div>
      <div>
        {orders === "today"
          ? todayOrders
          : orders === "done today"
          ? todayDoneOrders
          : allOrders}
      </div>
    </div>
  );
}

export default observer(Admin);
