import { makeObservable, observable, runInAction } from "mobx";
import instance from "./instance";

class AddressStore {
  addresses = [];
  loading = false;

  address = {
    area: "",
    block: "",
    ave: "",
    street: "",
    house: "",
    name: "",
    lastName: "",
    link: "",
    floor: "",
    appt: "",
    long: "",
    lant: "",
  };

  constructor() {
    makeObservable(this, {
      addresses: observable,
      loading: observable,
      address: observable,
    });
  }

  setAddress = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(value);
    this.address = { ...this.address, [name]: value };
  };

  fetchAddresses = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await instance.get("/invoices/address");
      runInAction(() => {
        this.addresses = res.data;
        this.loading = false;
      });
    } catch (error) {
      console.error(error);
    }
  };
  setDefault = async (id) => {
    try {
      const res = await instance.post(`/invoices/address/default/${id}`);
      if (res.data.message === "failed") {
        return;
      } else {
        runInAction(() => {
          this.loading = true;
        });
        const address = this.addresses.find((i) => i.id === id);
        const defaultAddress = this.addresses.find((i) => i.default);
        address.default = true;
        if (defaultAddress) {
          defaultAddress.default = false;
        }
        runInAction(() => {
          this.loading = false;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  get defaultAddress() {
    const defaultAddress = this.addresses.find((i) => i.default);
    return defaultAddress;
  }
  addAddress = async (state) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const newAddress = await instance.post(
        `/invoices/new-address`,
        this.address
      );
      runInAction(() => {
        this.addresses.push(newAddress);
        this.loading = false;
      });

      window.location.href = `/${state}`;
    } catch (error) {
      console.error(error);
    }
  };
}

const addressStore = new AddressStore();
// addressStore.fetchAddresses();
export default addressStore;
