// import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import addressStore from "./addressStore";
import authStore from "./authStore";
import instance from "./instance";

class InvoiceStore {
  items = [];
  slots = [];
  loading = true;
  discount = 0;
  order = null;
  areas = null;
  slot = "";
  invoiceToChange = null;
  change_reason = null;
  online_change = false;
  address = {
    area: "",
    block: "",
    ave: "",
    street: "",
    house: "",
    name: "",
    lastName: "",
  };
  homeservice = false;
  loadingArea = false;
  date = "";

  constructor() {
    makeAutoObservable(this);
  }

  setDiscount = async (discount) => {
    runInAction(() => {
      this.discount = discount;
    });
  };

  checkAddress = () => {
    if (this.homeservice === false) {
      return true;
    } else if (this.address.area === "") {
      toast.error("الرجاء اختيار المنطقة");
      return false;
    } else if (this.address.block === "") {
      toast.error("الرجاء اختيار القطعة.");
      return false;
    } else if (this.address.street === "") {
      toast.error("الرجاء اختيار الشارع");
      return false;
    } else if (this.address.house === "") {
      toast.error("الرجاء اختيار المنزل");
      return false;
    }
  };

  get defaultTab() {
    const homeservice = localStorage.getItem("homeservice");
    if (homeservice === "true") {
      return "tab2";
    } else {
      return "tab1";
    }
  }

  setHomeService = async (homeservice) => {
    localStorage.setItem("homeservice", JSON.stringify(homeservice));

    runInAction(() => {
      this.homeservice = homeservice;
    });
  };
  setDate = async (date) => {
    localStorage.setItem("date", JSON.stringify(date));

    runInAction(() => {
      this.date = date;
    });
  };

  setAddress = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    runInAction(() => {
      this.address = { ...this.address, [name]: value };
    });
    await localStorage.setItem("address", JSON.stringify(this.address));
  };
  addItemToInvoice = async (newItem) => {
    if (this.items.length === 0) {
      await localStorage.setItem("time", JSON.stringify(new Date()));
    }
    const foundItem = this.items.find((item) => item.itemId === newItem.itemId);
    if (foundItem) {
      return (
        (foundItem.quantity = foundItem.quantity + 1),
        (foundItem.price = newItem.item_price * foundItem.quantity)
      );
    } else {
      runInAction(() => {
        this.items.push(newItem);
      });
    }
    await localStorage.setItem("cart", JSON.stringify(this.items));
  };

  quantityItem = async (newItem) => {
    if (newItem.quantity <= 1) {
      this.removeItemFromInvoice(newItem.itemId);
    } else {
      const foundItem = this.items.find(
        (item) => item.itemId === newItem.itemId
      );
      return (
        (foundItem.quantity = foundItem.quantity - 1),
        (foundItem.price = newItem.item_price * foundItem.quantity)
      );
    }
    await localStorage.setItem("cart", JSON.stringify(this.items));
  };

  get totalPrice() {
    let total = 0;

    this.items.forEach((item) => {
      total += item.price;
    });

    if (this.homeservice && total >= 10) {
      total += 3.5;
    }
    return total - total * this.discount;
  }
  get itemsQnt() {
    let qnt = 0;

    this.items.forEach((item) => {
      qnt += item.quantity;
    });
    return qnt;
  }

  removeItemFromInvoice = async (itemId) => {
    runInAction(() => {
      this.items = this.items.filter((item) => item.itemId !== itemId);
    });
    await localStorage.setItem("cart", JSON.stringify(this.items));
  };

  cancelCheckout = async () => {
    runInAction(() => {
      this.items = [];
    });
  };
  setOldInvoice = async (invoice) => {
    runInAction(() => {
      this.invoiceToChange = parseInt(invoice);
    });
  };
  setChangeReason = async (reason) => {
    runInAction(() => {
      this.change_reason = reason;
    });
  };

  checkout = async (phone_number, payment, date, tryAgain) => {
    try {
      if (!tryAgain) {
        const invoice = {
          items: this.items,
          price: this.totalPrice,
          phone_number: phone_number
            ? phone_number?.toString()
            : authStore.user?.profile?.phone_number.toString(),
          payment: payment,
          date: date,
          discount: this.discount,
          selected_slot: this.slot,
          // area: this.address.area ?? "",
          // block: this.address.block ?? "",
          // street: this.address.street ?? "",
          // ave: this.address.ave ?? "",
          // house: this.address.house ?? "",
          addressId: addressStore.defaultAddress?.id,
          homeservice: this.homeservice,
        };

        const res = await instance.post(
          `/invoices/checkout_online/${1}/${1}`,
          invoice
        );
        this.order = res.data;
        await localStorage.setItem("order", JSON.stringify(res.data));

        // window.location.href = res.data.paymentURL;
      }
      if (tryAgain || this.order.status === "success") {
        // this.items = [];
        window.location.href = `https://api.salonatkw.com/k-net?amount=${this.order.price.toFixed(
          2
        )}&orderId=${this.order.invoiceId}`;
        // runInAction(() => {
        //   this.items = [];
        // });
        toast.success("يتم تحويلكم لصفحة الكي نت");
      } else {
        toast.error(this.order.messgae);
      }
      // alert("You will be refirected to Knet, Thanks.");
    } catch (error) {
      console.log(error);
    }
  };
  clearCArt = async (phone_number, payment, date) => {
    try {
      runInAction(() => {
        this.items = [];
      });
      localStorage.removeItem("cart");
    } catch (error) {
      console.log(error);
    }
  };
  fetchItems = async () => {
    const oldTime = localStorage.getItem("time");

    // Check if the stored time exists
    if (oldTime) {
      // Parse the stored time as a Date object
      const previousTime = new Date(JSON.parse(oldTime));

      // Get the current time
      const currentTime = new Date();

      // Set the time 30 minutes ago
      const thirtyMinutesAgo = new Date(currentTime.getTime() - 30 * 60000);

      let items;
      let order;
      let slot;
      let info;
      let address;
      let homeservice;
      let date;
      if (previousTime > thirtyMinutesAgo) {
        items = await localStorage.getItem("cart");
        order = await localStorage.getItem("order");
        address = await localStorage.getItem("address");
        slot = await localStorage.getItem("slot");
        info = localStorage.getItem("info");
        homeservice = await localStorage.getItem("homeservice");
        date = await localStorage.getItem("date");
      }

      runInAction(() => {
        this.items = items ? JSON.parse(items) : [];
        this.order = order ? JSON.parse(order) : [];
        this.slot = slot ? JSON.parse(slot) : "";
        this.address = address ? JSON.parse(address) : {};
        this.change_reason = info ? JSON.parse(info.change_reason) : "";
        this.invoiceToChange = info ? JSON.parse(info.invoiceToChange) : "";
        this.online_change = info ? JSON.parse(info.online_change) : false;
        this.homeservice = homeservice ? JSON.parse(homeservice) : false;
        this.date = date ? JSON.parse(date) : "";
        this.loading = false;
      });
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  setSlot = (slot) => {
    runInAction(() => {
      this.slot = slot;
    });
    localStorage.setItem("slot", JSON.stringify(this.slot));
  };
  getSlots = async (date, homeservice) => {
    try {
      const body = { date, homeservice };
      const res = await instance.post(`/invoices/time-slots`, body);
      runInAction(() => {
        this.slots = res.data;
      });
    } catch (error) {
      console.log(error);
    }
  };

  getAreas = async () => {
    try {
      runInAction(() => {
        this.loadingArea = true;
      });
      const areas = await instance.get("/invoices/areas");
      runInAction(() => {
        this.areas = areas.data;
        this.loadingArea = false;
      });
    } catch (error) {
      console.log(error);
    }
  };
}

const invoiceStore = new InvoiceStore();
invoiceStore.fetchItems();
invoiceStore.getAreas();
export default invoiceStore;
