// import { Fab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import addressStore from "./stores/addressStore";
// import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router";
import AddressItem from "./AddressItem";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";

function Addresses() {
  const history = useHistory();
  const previousPage = history.location.state || "none";
  if (addressStore.loading)
    return (
      <div className="profile" style={{ height: "100vh" }}>
        <Spinner />
      </div>
    );

  const handleDefault = (id) => {
    addressStore.setDefault(id);
    if (previousPage === "cart") {
      history.push({ pathname: "/cart", state: "address" });
    }
  };

  const addNewAddress = () => {
    if (previousPage === "cart") {
      history.push({ pathname: "/new-address", state: "cart" });
    } else {
      history.push("/new-address");
    }
  };
  const address = addressStore.addresses.map((i) => (
    <AddressItem key={i.id} address={i} onClick={() => handleDefault(i.id)} />
  ));
  return (
    <div className="profile" style={{ position: "relative" }}>
      {address}
      {/* <Fab
        onClick={() => history.push("/new-address")}
        style={{
          color: "white",
          backgroundColor: "#c39e81",
          position: "fixed",
          bottom: 100,
          left: 20,
        }}
        aria-label="add">
        <AddIcon />
      </Fab> */}
      <Button
        style={{
          width: "100%",
          marginTop: 23,
          backgroundColor: "#c39e81",
          borderColor: "#c39e81",
        }}
        // variant={"outline-danger"}
        onClick={() => addNewAddress()}>
        اضافة عنوان جديد
      </Button>{" "}
    </div>
  );
}

export default observer(Addresses);
