import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import CategoryList from "./CategoryList";
import categoriesStore from "./stores/categoriesStore";

function ProductList() {
  useEffect(() => {
    categoriesStore.fetchCategories();
  }, []);
  const { catId } = useParams();
  const cat = categoriesStore.getCatById(catId);
  return (
    <>
      <div className="fixedCategories customer">
        <CategoryList cat={cat} />
      </div>
      <div className="massage">
        <h1>Please Use a phone to be able to see the contant</h1>
      </div>
    </>
  );
}

export default observer(ProductList);
