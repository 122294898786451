// import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import invoiceStore from "./stores/cartStore";

const Failed = () => {
  if (invoiceStore.loading) return <Spinner />;

  const info = window.location.search
    .split(/&/)
    .includes("??paymentFor=SUBSCRIPTION")
    ? window.location.search.split(/&/).slice(1)
    : window.location.search.split(/&/);
  return (
    <div
      // className="d-flex justify-content-center flex-wrap align-content-center p-5"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        direction: "ltr",
      }}>
      <h1>فشلت عملية الدفع</h1>
      <AiOutlineCloseCircle style={{ fontSize: 50, color: "red" }} />
      <div className="payment-details-box order-details-box">
        <ul>
          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">Order Number: </p>
              <p className="details"> {invoiceStore.order.invoiceId}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">Payment ID:</p>
              <p className="details"> {info[0].split("=")[1]}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">Refrence ID:</p>
              <p className="details"> {info[4].split("=")[1]}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">Result</p>
              <p className="details">
                {" "}
                {info[1].split("=")[1].includes("Expired")
                  ? "Link is expired"
                  : info[1].split("=")[1]}
              </p>
            </div>
          </li>

          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">Date : </p>
              <p className="details"> {invoiceStore.order.date}</p>
            </div>
          </li>

          <li>
            <div className="d-flex align-items-center align-content-center ">
              <p className="details-title">total : </p>
              <p className="details"> {invoiceStore.order.price} KWD</p>
            </div>
          </li>
        </ul>
      </div>

      <div className="bottomCart" style={{ height: 70 }}>
        <button
          onClick={() => {
            info[1].split("=")[1] === "NOT+CAPTURED"
              ? invoiceStore.checkout(
                  invoiceStore.order.phone,
                  "online",
                  invoiceStore.order.appointmentDate,
                  false
                )
              : invoiceStore.checkout(
                  invoiceStore.order.phone,
                  "online",
                  invoiceStore.order.appointmentDate,
                  true
                );
          }}>
          <div className="col-12">
            {/* <Link
          to="/cart"
          style={{ color: "black", fontSize: 25, marginTop: 10 }}
        > */}
            المحاولة مره اخرى {/* </Link> */}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Failed;
