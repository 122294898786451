import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
// import { Link } from "react-router-dom";
import CategoryItem from "./CategoryItem";
import ProductItem from "./ProductItem";
// import invoiceStore from "./stores/cartStore";

import categoriesStore from "./stores/categoriesStore";

function CategoryList({ setCategory, cat }) {
  const [servicesCat, setServiceCat] = React.useState({ services: [] });
  // const [productsCat, setProductsCat] = React.useState({ products: [] });
  // const [offersCat, setOffersCat] = React.useState({ offers: [] });
  // const [products, setProducts] = React.useState([]);
  // const [offers, setOffers] = React.useState([]);
  const scrollContainerRef = useRef(null);
  const scrollToCategory = (categoryId) => {
    const categoryElement = document.getElementById(categoryId);
    if (categoryElement) {
      const categoryPosition = categoryElement.getBoundingClientRect().left;
      scrollContainerRef.current.scrollLeft = categoryPosition - 10;
    }
  };

  // Usage:

  useEffect(() => {
    setServiceCat(cat);
    // if (categoriesStore.loading) {
    // }
  }, [cat]);
  if (categoriesStore.loading === true) return <Spinner animation="border" />;

  const servicesList = categoriesStore.services
    .filter(
      (i) =>
        i.id !== 23 &&
        i.id !== 22 &&
        i.id !== 21 &&
        i.id !== 17 &&
        i.id !== 28 &&
        i.id !== 29
    )
    .map((category) => (
      <CategoryItem
        category={category}
        key={category.id}
        setCategory={setCategory}
        servicesCat={servicesCat}
        setServiceCat={setServiceCat}
        scrollToCategory={scrollToCategory}
      />
    ));
  const list = servicesCat.services ?? servicesCat.products;
  const servicesIList = list
    // .filter((i) => i.categoryId === parseInt(catId))
    .map((product) => (
      <ProductItem
        product={product}
        key={product.id}
        type={
          servicesCat.id === 0
            ? "offer"
            : servicesCat.id === 27
            ? "package"
            : servicesCat.id === 9
            ? "product"
            : "service"
        }
      />
    ));

  // const offersList = offers.map((product) => (
  //   <ProductItem product={product} key={product.id} type="offer" />
  // ));

  return (
    <>
      {/* <img
        src={servicesCat.image}
        alt={servicesCat.name}
        style={{
          height: 300,
          width: "100%",
          borderRadius: 10,
          marginBottom: 10,
          objectFit: "fill",
        }}
      /> */}
      <div>
        <div>
          <div className="horizontal-slider" ref={scrollContainerRef}>
            <div className="categoriesTab">{servicesList}</div>
          </div>
          <div
            style={{
              display: "inline-block",
              width: "90%",
              marginTop: 20,
              marginBottom: 90,
            }}>
            {servicesIList}
          </div>
        </div>
        {/* <div className="bottomCart" style={{ height: 80 }}>
        <button>
          <Link to="/changeAppoitnemnt" style={{ color: "white" }}>
            <div>
              <p style={{ fontSize: 20, margin: 0 }}>تغيير الموعد</p>
            </div>
          </Link>
        </button>
      </div> */}

        {/* <h2>Products</h2>
      <div>
        <div className="horizontal-slider">
          <div className="categoriesTab">{productsList}</div>
        </div>
        <div style={{ display: "inline-block", width: "90%" }}>
          {productsIList}
        </div>
      </div> */}
      </div>
    </>
  );
}

export default observer(CategoryList);
