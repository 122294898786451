import "bootstrap/dist/css/bootstrap.min.css";
import { BsBag } from "react-icons/bs";
import { observer } from "mobx-react";
// import CartList from "./components/CartList";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
// import Checkout from "./components/Checkout";

import invoiceStore from "./components/stores/cartStore";

import { useEffect } from "react";
import authStore from "./components/stores/authStore";
import { ToastContainer } from "react-toastify";
// import { IoIosArrowBack } from "react-icons/io";

import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Paper } from "@mui/material";
import Home from "./Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeOutlined, Person } from "@mui/icons-material";
// import RestoreIcon from "@mui/icons-material/Restore";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import LocationOnIcon from "@mui/icons-material/LocationOn";

// import Welcome from "./components/Welcome";
// import Categories from "./components/categories/Categories";
function App() {
  const [value, setValue] = useState(0);
  useEffect(() => {
    authStore.checkForToken();
    if (window.location.pathname.includes("/products")) {
      setValue(0);
    } else if (window.location.pathname === "/cart") {
      setValue(1);
    } else if (
      window.location.pathname === "/setting" ||
      window.location.pathname === "/profile" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signin"
    ) {
      setValue(2);
    }
  }, []);
  // const [path, setPath] = useState(window.location.pathname);
  // const View =
  //   value === 0 ? <Home /> : value === 1 ? <CartList /> : <Checkout />;
  const history = useHistory();

  return (
    <>
      <div className="App">
        <div className="nav-bar">
          <Link
            to={"/cart"}
            // onClick={() => setPath("/cart")}
            style={{ textDecoration: "none", color: "black", width: "10%" }}>
            <BsBag
              style={{ position: "relative", fontSize: 25, color: "white" }}
            />
            <div
              style={{
                position: "absolute",
                top: 20,
                right: 15,
                backgroundColor: "white",
                borderRadius: 100,
                width: 20,
                height: 20,
                textAlign: "center",
                color: "white",
              }}>
              <p
                className="m-0"
                style={{ lineHeight: "20px", color: "#c39e81" }}>
                {invoiceStore.itemsQnt}
              </p>
            </div>
          </Link>

          <Link
            to={"/"}
            style={{ color: "white", textDecoration: "none", width: "60%" }}
            onClick={() => history.push("/")}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                letterSpacing: 10,
                width: "78%",
              }}>
              <h1 className="m-0" style={{ fontSize: 25 }}>
                PEONY
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "center",
                  fontSize: 10,
                  fontWeight: 300,
                  letterSpacing: 6,
                }}>
                <p style={{ margin: 0, marginRight: "9px" }}> LOUNGE </p>
                <p style={{ margin: 0 }}> SPA </p>
              </div>
            </div>
          </Link>

          <div style={{ width: "10%" }}>
            <Link
              onClick={() => history.goBack()}
              to={"/"}
              style={{
                // position: "fixed",
                top: 25,
                left: 40,
                fontSize: 15,

                textDecoration: "none",
                color: "black",
              }}></Link>
          </div>
        </div>
        <div>
          <Home />
        </div>
      </div>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}>
        <div className="nn">
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue === 0) {
                history.push("/");
              } else if (newValue === 1) {
                history.push("/cart");
              } else {
                history.push("/setting");
              }
            }}>
            <BottomNavigationAction label="الرئيسية" icon={<HomeOutlined />} />
            <BottomNavigationAction label="السله" icon={<BsBag />} />
            <BottomNavigationAction label="الملف الشخصي" icon={<Person />} />
          </BottomNavigation>
        </div>
      </Paper>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        draggable
        // theme="dark"
      />
    </>
  );

  // return (
  //   <>
  //     {/* {authStore.welcome ? (
  //       <div
  //         className="bottomCart"
  //         style={{ height: "100vh", width: "100%", zIndex: 10000, padding: 0 }}>
  //         <button
  //           onClick={() => authStore.skipWelcome()}
  //           style={{
  //             position: "absolute",
  //             bottom: 10,
  //             marginLeft: "auto",
  //             marginRight: "auto",
  //             left: 0,
  //             right: 0,
  //           }}>
  //           Skip
  //         </button>
  //         <Welcome />
  //       </div>
  //     ) : null} */}
  //   </>
  // );
}

export default observer(App);
