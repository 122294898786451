import { observer } from "mobx-react";
import React from "react";
// import { Badge } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
// import { Redirect } from "react-router-dom";
import authStore from "./stores/authStore";
import invoiceStore from "./stores/cartStore";

function ProductItem({ product, type }) {
  const newItem = {
    quantity: 1,
    hasLoyality: product.hasLoyality,
    type: type,
    itemId: type + product.id,
    price: product.price,
    item_price: product.price,
    name: type === "package" ? "package" + product.name : product.name,
    name_ar: type === "package" ? "اشتراك" + product.name_ar : product.name_ar,
    is_package: type === "package" ? true : false,
    start_using_time: 5,
    checkServiceId: product.id,
    serviceId: type === "offer" ? null : product.id,
    offerId: type === "offer" ? product.id : null,
  };
  const foundItem = invoiceStore.items.find(
    (i) => i.itemId === type + product.id
  );
  return (
    // <Link to={`/products/${product.slug}`} style={{ textDecoration: "none" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignContent: "flex-end",
        borderWidth: 1,
        borderColor: "#c4c4c4",
        borderBottomStyle: "solid",
        padding: 10,
        // paddingBottom: 0,
        height: 100,
        // borde,
        borderRightStyle: foundItem ? "solid" : null,
        borderRightColor: "#c39e81",
        borderRightWidth: 2,
        marginTop: 25,
        paddingBottom: 5,
      }}>
      <div
        // className="product"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          // backgroundColor: "red",
          // justifyContent: "space-between",
          alignItems: "flex-start",
          alignContent: "flex-start",
        }}>
        {/* <p className="quantity">{foundItem ? foundItem.quantity : 0} x </p> */}
        <p
          style={{
            marginBottom: 5,
            margin: 0,
            padding: 0,
            // backgroundColor: "red",
            // fontWeight: "bold",
          }}>
          {product.name_ar}{" "}
          {product.hasLoyality ? (
            // <Badge className="badge">Loyality</Badge>
            <AiFillStar color="#c39e81" />
          ) : null}
        </p>

        <p
          style={{
            margin: 0,
            marginTop: 3,
            padding: 0,
            fontSize: 12,
            // backgroundColor: "red",
            color: "gray",
            textAlign: "right",
            maxLines: 2,
            overflow: "hidden",
          }}>
          {product.description}{" "}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "40%",
          // backgroundColor: "red",
          // justifyContent: "space-between",
          alignItems: "flex-end",
          alignContent: "flex-end",
        }}>
        <p style={{ marginBottom: 2 }}>{product.price.toFixed(2)} KD </p>
        {product.oldPrice !== 0 && product.oldPrice && (
          <p
            style={{
              marginBottom: 2,
              textDecoration: "line-through",
              color: "grey",
              fontSize: 15,
            }}>
            {product.oldPrice?.toFixed(2)} KD{" "}
          </p>
        )}

        {!foundItem ? (
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: 10,
              // lineHeight: 0,
              // marginLeft: 10,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",

              backgroundColor: "#c39e81",
            }}
            onClick={() =>
              authStore.user
                ? invoiceStore.addItemToInvoice(newItem)
                : (window.location.href = "/signin")
            }>
            <p style={{ margin: 0, marginTop: 2, color: "white" }}>+</p>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}>
            {/* <BsTrash color={"red"} onClick={handleDelete} /> */}

            <div
              style={{
                width: "100%",
                height: 30,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "#c39e81",
                borderRadius: 50,
                // marginTop: 20,
                // marginBottom: -20,
                // marginLeft: 80,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "white",
                  height: "100%",
                  lineHeight: 1.4,
                  width: "30%",
                  margin: 0,

                  borderTopRightRadius: 25,
                  borderBottomRightRadius: 25,
                }}
                onClick={() => invoiceStore.quantityItem(foundItem)}>
                -
              </p>
              <p
                style={{
                  fontSize: 17,
                  color: "white",
                  margin: 0,
                  fontWeight: "bold",
                }}>
                {foundItem.quantity}
              </p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "white",
                  height: "100%",
                  margin: 0,
                  width: "30%",
                  lineHeight: 1.4,
                  borderTopLeftRadius: 25,
                  borderBottomLeftRadius: 25,
                }}
                onClick={
                  () =>
                    type === "package"
                      ? null
                      : invoiceStore.addItemToInvoice(newItem)
                  // null
                }>
                +
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
    // </Link>
  );
}

export default observer(ProductItem);
