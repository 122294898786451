import { action, makeObservable, observable } from "mobx";
import instance from "./instance";
// import instance from "./instance";
import offersImage from "../categories/offersImage.jpeg";
class CategoryStore {
  services = [];
  offers = [];
  products = [];
  loading = true;

  constructor() {
    makeObservable(this, {
      services: observable,
      offers: observable,
      products: observable,
      loading: observable,
      //   deleteCategory: action,
      fetchCategories: action,
      //   updateCategory: action,
      //   addCategory: action,
    });
  }

  fetchCategories = async () => {
    try {
      this.loading = true;
      // const token =
      //   "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJwZW9ueSIsImVtYWlsIjoiUGVvbnlsb3VuZ2VzcGFAZ21haWwuY29tIiwidXNlcl90eXBlIjoidmVuZG9yIn0.lIxCxU2ob0N0p1_lDh9qFuaGrOZ0egGvSH3uEXD92gw";
      // instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      const services = await instance.get(`categories/services/${1}/${1}`);
      // const offers = await instance.get(`categories/offers/${1}/${1}`);
      const offers = await instance.get(`offers/available?vendorId=${1}`);

      const products = await instance.get(`categories/products/${1}/${1}`);
      this.services = [
        {
          name_ar: "عروض اليوم",
          services: offers.data,
          name: "offers",
          id: 0,
          image: offersImage,
        },
        ...services.data,
        ...products.data,
      ];
      this.offers = offers.data;
      this.products = products.data;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  };

  getCatById = (catId) => {
    return this.services.find((c) => c.id === parseInt(catId));
  };
  // addCategory = async (category) => {
  //   try {
  //     const newCategory = await instance.post(`/catgeories/${category}`);
  //     this.categories.push(newCategory);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //   updateCategory = (updatedItem, extra) => {
  //     try {
  //       const item = this.categories.find((item) => item.id === updatedItem.id);
  //       item.extras = item.extras.filter((_extra) => _extra !== extra.id);
  //       item.price = item.price - extra.price;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   deleteCategory = async (productId) => {
  //     try {
  //       this.categories = this.categories.filter(
  //         (product) => product.id !== productId
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
}

const categoriesStore = new CategoryStore();
categoriesStore.fetchCategories();
export default categoriesStore;
