import React, { useEffect } from "react";
import image from "./categories/peony.jpeg";

function CategoryItem({
  category,
  servicesCat,
  setServiceCat,
  productsCat,
  setProductsCat,
  offersCat,
  setOffersCat,
  scrollToCategory,
}) {
  const setCategory = setServiceCat || setProductsCat || setOffersCat;
  const _category = servicesCat || productsCat || offersCat;
  useEffect(() => {
    scrollToCategory(_category.id);
    // eslint-disable-next-line
  }, []);
  return (
    // <Link to={`/products`} onClick={() => setCategory(category)}>
    <div
      id={category.id}
      className={category === _category ? "tab active" : "tab"}
      onClick={() => setCategory(category)}
      style={{ height: 350, width: 300, padding: 0, marginLeft: 10 }}>
      <img
        src={category.image ?? image}
        alt={category.name}
        style={{
          height: 300,
          width: 300,
          borderRadius: 10,
          marginBottom: 10,
          objectFit: "fill",
        }}
      />
      <p style={{ fontSize: 20, textAlign: "right", marginRight: 20 }}>
        {category.name_ar}
      </p>
    </div>
    // </Link>
  );
}

export default CategoryItem;
