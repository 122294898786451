// import { ArrowBack } from "@mui/icons-material";
import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";

import { Card, CardContent, Typography } from "@mui/material";
import { GiCircle } from "react-icons/gi";
import { BsFillCircleFill } from "react-icons/bs";
// import { FiGift } from "react-icons/fi";
import authStore from "./stores/authStore";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
// import { Button } from "@mui/base";
import lotyalityStore from "./stores/loyalityStore";
// import { Badge } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { Spinner } from "react-bootstrap";

function Setting() {
  useEffect(() => {
    const f = async () => {
      await lotyalityStore.fetchLoyalitys();
    };
    f();
  }, []);
  if (!authStore.user) return <Redirect to="/signin" />;

  if (lotyalityStore.loading)
    return (
      <div className="fixedCategories">
        <Spinner />
      </div>
    );
  const getCircle = (n, l) => {
    let key = 0;
    let list = [];
    for (let i = 1; i <= n; i++) {
      list.push(<BsFillCircleFill key={key} size={35} color={"white"} />);
      key = key + 1;
    }
    for (let i = 1; i <= l - 1 - n; i++) {
      list.push(<GiCircle size={35} key={key} color={"white"} />);
      key = key + 1;
    }
    list.push(
      <div className="circle" key={key}>
        <p
          style={{
            fontSize: 12,
            color: "white",
            // color: "#c39e81",
            margin: 0,
            padding: 0,
          }}>
          مجانا
        </p>
      </div>
    );
    return list;
  };
  return (
    <div className="profile">
      <div
        className="profile_field"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
        }}>
        <input
          defaultValue="نقاط الولاء"
          readOnly
          style={{
            // textAlign: "center",
            fontWeight: "bold",
            // color: "white",
            color: "#c39e81",
            backgroundColor: "white",
            border: 0,
            borderBottomColor: "#c39e81",
          }}
        />
        <p style={{ marginLeft: 12 }}>
          {/* <Badge className="badge">Points</Badge> */}
          <AiFillStar color="#c39e81" />
        </p>
      </div>

      <Card
        className="element"
        elevation={10}
        style={{
          backgroundColor: "transparent",
          maxHeight: 352,
          width: "100%",
          overflowY: "scroll",
          marginBottom: 10,
        }}>
        {lotyalityStore.loyalities.map((i, index) => (
          <Card
            key={i.id}
            sx={{ minWidth: 275 }}
            style={{
              marginBottom:
                index === lotyalityStore.loyalities.length - 1 ? 0 : 10,
              backgroundColor: "#c39e81",
            }}
            elevation={10}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "flex_start",
                  width: "100%",
                }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    margin: 0,
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "right",
                    marginRight: 0.5,
                  }}
                  color="text.secondary"
                  gutterBottom>
                  {i.name_ar ?? i.name}
                </Typography>
                <div
                  style={{
                    marginTop: 15,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}>
                  {getCircle(i.numOfUse, i.limit)}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </Card>
      <div className="list">
        <a href="profile">
          <div className="listItem">
            <p>معلومات الحساب</p> <IoIosArrowBack />
          </div>
        </a>
        <a href="address">
          <div className="listItem">
            <p>عناوين التوصيل</p> <IoIosArrowBack />
          </div>
        </a>
      </div>
    </div>
  );
}

export default observer(Setting);
