import { observer } from "mobx-react";
import React, { useState } from "react";
import { Form, Button, Col, FloatingLabel } from "react-bootstrap";
import { Redirect } from "react-router";
import authStore from "../stores/authStore";
// import authStore from "../../stores/authStore";

function Signup() {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({});
  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      setUser({
        ...user,
        phone_number: e.target.value,
        username: e.target.value,
      });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      authStore.register(user);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  if (authStore.user) return <Redirect to="/" />;
  return (
    <div className="profile">
      <p className="title">{"createAccount"}</p>
      <Col md={5} className="auth-container">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* <Row className="mb-3 px-2"> */}
          {/* <FloatingLabel label={"الاسم"} className="p-1" as={Col}>
            <Form.Control
              required
              type="text"
              name="name"
              placeholder={"الاسم"}
              onChange={handleChange}
            />
          </FloatingLabel> */}
          {/* <FloatingLabel label={"lastName"} className="p-1" as={Col}>
            <Form.Control
              required
              type="text"
              name="last_name"
              placeholder={"lastName"}
              onChange={handleChange}
            />
          </FloatingLabel> */}
          {/* <FloatingLabel label={"اسم المستخدم"} className="p-1" as={Col}>
            <Form.Control
              type="text"
              placeholder={"username"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
              name="username"
            />
          </FloatingLabel> */}
          <FloatingLabel label={"رقم الهاتف"} className="p-1" as={Col}>
            <Form.Control
              type="number"
              placeholder={"phone_number"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
              name="phone_number"
            />
          </FloatingLabel>
          <FloatingLabel label={"الايميل"} className="p-1" as={Col}>
            <Form.Control
              type="email"
              placeholder={"email"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
              name="email"
            />
          </FloatingLabel>
          <FloatingLabel label={"الرقم السري"} className="p-1" as={Col}>
            <Form.Control
              type="password"
              placeholder={"password"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
              name="password"
            />
          </FloatingLabel>

          {/* </Row> */}

          {/* <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom04">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" placeholder="State" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label>Zip</Form.Label>
          <Form.Control type="text" placeholder="Zip" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
        </Form.Group>
      </Row> */}
          {/* <Form.Group className="mb-3">
        <Form.Check
          required
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group> */}
          <Form.Group className="p-1">
            <Button
              type="submit"
              style={{ backgroundColor: "#c39e81", borderColor: "#c39e81" }}
              className=" w-100">
              {"التسجيل"}
            </Button>
          </Form.Group>
        </Form>
        <div className="d-flex w-100 mt-3 justify-center">
          {/* <p className="decoration">{"hasAccount"}</p> */}

          <a href="signin" className="ps-1">
            {"لديك حساب ؟ سجل الدخول الان"}
          </a>
        </div>
      </Col>
    </div>
  );
}

export default observer(Signup);
