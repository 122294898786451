import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Address from "./components/Address";
import Addresses from "./components/Addresses";
import Admin from "./components/Admin";
import ForgetPassword from "./components/auth/ForgetPassword";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import CartList from "./components/CartList";
import ChangeAppointment from "./components/ChangeAppointment";
import Checkout from "./components/Checkout";
import Failed from "./components/Failed";
import OrderDetails from "./components/OrderDetails";
import ProductList from "./components/ProductList";
import Profile from "./components/Profile";
import Setting from "./components/Setting";
import authStore from "./components/stores/authStore";
import Successful from "./components/Successful";
import MapContainer from "./components/GoogleMap";

function Home() {
  //   const [path, setPath] = useState(window.location.pathname);
  return (
    <Switch>
      <Route path={"/cart"}>
        <CartList />
      </Route>
      <Route path={"/map"}>
        <MapContainer />
      </Route>
      <Route path={"/new-address"}>
        <Address />
      </Route>
      <Route path={"/address"}>
        <Addresses />
      </Route>
      <Route path={"/setting"}>
        <Setting />
      </Route>
      <Route path={"/forgetPassword"}>
        <ForgetPassword />
      </Route>
      <Route path={"/signin"}>
        <Signin />
      </Route>
      <Route path={"/signup"}>
        <Signup />
      </Route>
      <Route path={"/profile"}>
        {authStore.user ? <Profile /> : <Signin />}
      </Route>
      <Route path={"/order-details"}>
        <OrderDetails />
      </Route>
      <Route path={"/checkout"}>
        <Checkout />
      </Route>
      <Route path={"/success"}>
        <Successful />
      </Route>
      <Route path={"/failed"}>
        <Failed />
      </Route>
      <Route path={"/admin/checkout/orders"}>
        <Admin />
      </Route>
      <Route path={"/products/:catId"}>
        <ProductList />
        {/* <Slots /> */}
      </Route>
      <Route path={"/changeAppoitnemnt"}>
        <ChangeAppointment />
        {/* <Slots /> */}
      </Route>
      <Route path={"/"}>
        <Redirect to={"/products/0"} />
      </Route>
    </Switch>
  );
}

export default Home;
