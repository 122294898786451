import { observer } from "mobx-react";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import invoiceStore from "./stores/cartStore";

function ChangeAppointment() {
  const [validated, setValidated] = useState(false);
  const newItem = {
    quantity: 1,
    itemId: "change" + 0,
    price: 1,
    item_price: 1,
    name: "fees",
    name_ar: "عموله",
  };
  const handleCheckout = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);
    invoiceStore.addItemToInvoice(newItem);
    window.location.href = "/cart";
  };
  return (
    <div style={{ marginTop: 100 }}>
      <Form validated={validated}>
        <div>
          <div style={{ margin: 15 }}>
            {/* <label style={{ marginLeft: 5 }}>رقم الهاتف</label> */}
            <Form.Control
              required
              type="text"
              placeholder=" الفاتوره"
              onChange={(e) => {
                invoiceStore.setOldInvoice(e.target.value);
              }}
            />
          </div>
          <div style={{ paddingRight: 15, marginBottom: 10 }}>
            <Form.Check
              style={{ fontSize: 18 }}
              reverse
              required
              checked={
                invoiceStore.change_reason === "change appointment"
                  ? true
                  : false
              }
              onChange={() =>
                invoiceStore.setChangeReason("change appointment")
              }
              name="group1"
              type={"radio"}
              id={`radio`}
              label={`تغير الموعد`}
            />
          </div>
          <div style={{ paddingRight: 15, marginBottom: 10 }}>
            <Form.Check
              style={{ fontSize: 18 }}
              reverse
              required
              checked={
                invoiceStore.change_reason === "cancel appointment"
                  ? true
                  : false
              }
              onChange={() =>
                invoiceStore.setChangeReason("cancel appointment")
              }
              name="group1"
              type={"radio"}
              id={`raido`}
              label={"الغاء الموعد"}
            />
          </div>
        </div>
        <div className="bottomCart" style={{ height: 150 }}>
          <div className="total">
            <p style={{ fontSize: 20, margin: 0 }}>المجموع:</p>
            <p style={{ fontSize: 20, margin: 0 }}> KWD 1</p>
          </div>
          <button onClick={handleCheckout}>اضافه للسله</button>
        </div>
      </Form>
    </div>
  );
}

export default observer(ChangeAppointment);
