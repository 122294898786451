import { Divider } from "@mui/material";
import React from "react";
import { PiStarThin } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";

function AddressItem({ address, onClick }) {
  return (
    <>
      {" "}
      <div
        key={address.id}
        onClick={onClick}
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          alignContent: "flex-start",
          alignItems: "center",
          textAlign: "right",
        }}>
        <div>
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 12,
            }}>{`المنطقة:${address.area} ،`}</p>
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 12,
            }}>{` القطعه:${address.block} ، `}</p>
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 12,
            }}>{` الجاده:${address.ave}، الشارع:${address.street}، المنزل:${address.house}`}</p>
        </div>
        {address.default ? <AiFillStar color="#c39e81" /> : <PiStarThin />}
      </div>
      <Divider color={"#e9e9e9"} />
    </>
  );
}

export default AddressItem;
