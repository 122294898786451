import React from "react";

function OrderItem({ product }) {
  return (
    <div>
      <p>
        {product.quantity}*{product.name}
      </p>
      {product.souce === "" ? null : (
        <p style={{ color: "gray" }}> الصوص: {product.souce} </p>
      )}
      {product.extras === null ? null : (
        <p style={{ color: "gray" }}>الاضافات: {product.extras} </p>
      )}
      {product.note === "" ? null : (
        <p style={{ color: "gray" }}> الملاحضات: {product.note} </p>
      )}
      <div style={{ height: 1, width: "100%", backgroundColor: "gray" }}></div>
    </div>
  );
}

export default OrderItem;
