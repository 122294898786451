import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import invoiceStore from "./stores/cartStore";

function Slots({ date, setDate, formattedDate, homeservice }) {
  useEffect(() => {
    const getSlots = async () => {
      await invoiceStore.getSlots(date, homeservice);
    };
    getSlots();
  }, [date, homeservice]);
  const seletDate = (e) => {
    setDate(e.target.value);
    invoiceStore.setDate(e.target.value);
    invoiceStore.setSlot("");
  };
  const convert = (time) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const slots = invoiceStore.slots.map((i) => (
    <div
      key={i.time}
      onClick={() => invoiceStore.setSlot(i.time)}
      className={invoiceStore.slot === i.time ? "tab active" : "tab"}
      style={{
        direction: "rtl",
        width: 90,
        backgroundColor: invoiceStore.slot === i.time ? "#c39e81" : "#c4c4c4",
        color: invoiceStore.slot === i.time ? "white" : "black",
        padding: 5,
        margin: 5,
        borderRadius: 50,
        height: 35,
      }}>
      {convert(i.time)}
    </div>
  ));
  return (
    <div>
      <Form>
        <div>
          <div style={{ margin: 15, marginTop: 0 }}>
            {/* <label style={{ marginLeft: 5 }}>رقم الهاتف</label> */}
            <Form.Control
              required
              type="date"
              min={
                homeservice && formattedDate < "2023-10-03"
                  ? "2023-10-03"
                  : formattedDate
              }
              // defaultValue={
              //   homeservice && new Date(formattedDate) < new Date("2023-10-03")
              //     ? "2023-10-03"
              //     : date
              // }
              value={invoiceStore.date}
              onChange={seletDate}
            />
          </div>
        </div>
      </Form>

      <div
        className="horizontal-slider border-bottom"
        style={{ direction: "ltr" }}>
        <div className="categoriesTab">{slots}</div>
        {slots.length === 0 ? (
          <p> Fully Booked : لا يوجد مواعيد لايوم</p>
        ) : null}
      </div>
    </div>
  );
}

export default observer(Slots);
