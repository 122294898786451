// MapContainer.js
import React, { useState, useEffect, useRef } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import addressStore from "./stores/addressStore";
import invoiceStore from "./stores/cartStore";
import { Alert, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const MapContainer = ({ google, address }) => {
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState(null);
  const [unavailable, setAvailable] = useState(false);
  const autocompleteRef = useRef(null);
  const history = useHistory();
  const previousPage = history.location.state || "none";
  const addNewAddress = () => {
    if (unavailable) {
      toast.error("خارج نطاق التوصيل الرجاء اختيار منطقه اخرى");
    } else {
      if (previousPage === "cart") {
        history.push({ pathname: "/new-address", state: "cart" });
      } else {
        history.push("/new-address");
      }
    }
  };

  useEffect(() => {
    // Fetch user's location using Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });

          // Fetch detailed address using Geocoding API
          const geocoder = new google.maps.Geocoder();
          const latLng = new google.maps.LatLng(latitude, longitude);

          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                const street = results.find((i) => i.types.includes("route"));

                const govern = results.find((i) =>
                  i.types.includes("neighborhood")
                );
                addressStore.setAddress({
                  target: {
                    name: "long",
                    value: longitude,
                  },
                });
                addressStore.setAddress({
                  target: {
                    name: "lant",
                    value: latitude,
                  },
                });
                addressStore.setAddress({
                  target: {
                    name: "street",
                    value: street.address_components[0].long_name,
                  },
                });
                const selectedArea = invoiceStore.areas?.find(
                  (i) => i.area === govern.address_components[1].long_name
                );
                if (!selectedArea) {
                  setAvailable(true);
                } else {
                  setAvailable(false);
                }
                console.log(selectedArea.area_ar);
                addressStore.setAddress({
                  target: {
                    name: "block",
                    value: govern.address_components[0].long_name,
                  },
                });
                addressStore.setAddress({
                  target: {
                    name: "area",
                    value: selectedArea.area_ar,
                  },
                });
              } else {
                setError("Address not found");
              }
            } else {
              console.error("Geocoding API request failed:", status);
              setError("Error fetching address");
            }
          });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteRef.current = autocompleteService;
    handlePlaceChanged();
    // eslint-disable-next-line
  }, [google]);

  const handleMarkerDragend = (mapProps, map, dragendEvent) => {
    const newLat = dragendEvent.latLng.lat();
    const newLng = dragendEvent.latLng.lng();

    setMarkerPosition({ lat: newLat, lng: newLng });

    // Fetch detailed address using Geocoding API for the new marker position
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(newLat, newLng);
    const { lat, lng } = markerPosition;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    addressStore.setAddress({
      target: {
        name: "link",
        value: googleMapsLink,
      },
    });

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results.length !== 0) {
          const street = results.find((i) => i.types.includes("route"));

          const govern = results.find((i) => i.types.includes("neighborhood"));

          addressStore.setAddress({
            target: {
              name: "street",
              value: street.address_components[0].long_name,
            },
          });
          const selectedArea = invoiceStore.areas?.find(
            (i) => i.area === govern.address_components[1].long_name
          );
          if (!selectedArea) {
            setAvailable(true);
          } else {
            setAvailable(false);
          }
          console.log(selectedArea);
          addressStore.setAddress({
            target: {
              name: "block",
              value: govern.address_components[0].long_name,
            },
          });
          addressStore.setAddress({
            target: {
              name: "area",
              value: selectedArea.area_ar,
            },
          });
        } else {
          setError("Address not found");
        }
      } else {
        console.error("Geocoding API request failed:", status);
        setError("Error fetching address");
      }
    });
  };

  const mapStyles = {
    width: "100%",
    height: "93%",
    // borderRadius: 10,
  };

  //   const generateGoogleMapsLink = () => {
  //     const { lat, lng } = markerPosition;
  //     // window.open(googleMapsLink, '_blank');
  //     console.log(googleMapsLink);
  //   };

  const handlePlaceChanged = () => {
    const input = document.getElementById("autocomplete-input");
    const autocomplete = new google.maps.places.Autocomplete(input);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place && place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location;
        setMarkerPosition({ lat: lat(), lng: lng() });

        // Update the center of the map to the selected place
        setUserLocation({ lat: lat(), lng: lng() });

        // Fetch detailed address using Geocoding API for the selected place
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(lat(), lng());

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            if (results.length !== 0) {
              const street = results.find((i) => i.types.includes("route"));

              const govern = results.find((i) =>
                i.types.includes("neighborhood")
              );

              addressStore.setAddress({
                target: {
                  name: "street",
                  value: street.address_components[0].long_name,
                },
              });
              const selectedArea = invoiceStore.areas?.find(
                (i) => i.area === govern.address_components[1].long_name
              );
              if (!selectedArea) {
                setAvailable(true);
              } else {
                setAvailable(false);
              }
              addressStore.setAddress({
                target: {
                  name: "block",
                  value: govern.address_components[0].long_name,
                },
              });
              addressStore.setAddress({
                target: {
                  name: "area",
                  value: selectedArea.area_ar,
                },
              });
            } else {
              setError("Address not found");
            }
          } else {
            console.error("Geocoding API request failed:", status);
            setError("Error fetching address");
          }
        });
      } else {
        console.error("Invalid place object:", place);
        // Handle the case where the place object is not valid
        setError("Invalid place");
      }
    });
  };

  return (
    <div
      style={{
        position: "relative",
        top: 65,
        width: "100%",
        height: "85vh",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
      }}>
      {error && <p>Error: {error}</p>}
      {!unavailable ? null : ( // Content when selectedArea is found
        // Content when selectedArea is not found
        <Alert variant="warning" dismissible>
          <Alert.Heading>خارج نطاق التوصيل</Alert.Heading>
          <p>نأسف المنطقه المختاره خارج نطاق التوصيل</p>
        </Alert>
      )}
      <br />
      <Form.Control
        id="autocomplete-input"
        type="text"
        placeholder="ابحث"
        style={{
          width: "98%",
          padding: 5,
          margin: 5,
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 5,
        }}
        // defaultValue="Otto"
      />

      <Map
        google={google}
        zoom={15}
        style={mapStyles}
        initialCenter={userLocation}
        zoomControl={false} // Disable zoom control
        mapTypeControl={false} // Disable map type control
        streetViewControl={false}
        fullscreenControl={false}
        center={userLocation}
        onClick={handleMarkerDragend}>
        <Marker
          position={markerPosition}
          draggable={true}
          onDragend={handleMarkerDragend}
        />
      </Map>
      <Button
        onClick={addNewAddress}
        style={{
          width: "90%",
          marginTop: 23,
          position: "fixed",
          bottom: 90,
          right: 0,
          left: 0,
          marginRight: "auto",
          marginLeft: "auto",
          backgroundColor: "#c39e81",
          borderColor: "#c39e81",
        }}
        // variant={"outline-danger"}
      >
        Confirm
      </Button>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyA2aiNPP8AktRiIUMmIOidyUTfzm8ZN4Jo",
})(MapContainer);
