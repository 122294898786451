import { observer } from "mobx-react";
import React from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Spinner } from "react-bootstrap";
import authStore from "./stores/authStore";
import addressStore from "./stores/addressStore";
// import invoiceStore from "./stores/cartStore";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import invoiceStore from "./stores/cartStore";

function Address() {
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  const previousPage = history.location.state || "address";
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  if (addressStore.loading && authStore.loading) return <Spinner />;
  return (
    <>
      {/* <div style={{ height: 200, width: "100%" }}>
        <img
          onClick={() => history.push("/map")}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressStore.address.lant},${addressStore.address.long}&zoom=14&size=400x200&markers=${addressStore.address.lant},${addressStore.address.long}&key=AIzaSyA2aiNPP8AktRiIUMmIOidyUTfzm8ZN4Jo`}
          alt="Static Map"
          style={{
            width: "100%",
            // height: "100%",
            position: "relative",
            top: 75,
            zIndex: 1,
          }}
        />

      </div> */}
      <div className="profile">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} s="2" controlId="validationCustom01">
              {/* <Form.Label>الاسم الاول</Form.Label> */}
              <FloatingLabel controlId="floatingInput" label="الاسم الاول">
                <Form.Control
                  required
                  type="text"
                  name="name"
                  placeholder="الاسم الاول"
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.name}
                  defaultValue={authStore.user.profile.name}
                />
              </FloatingLabel>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} s="2" controlId="validationCustom02">
              {/* <Form.Label>العائلة</Form.Label> */}

              <FloatingLabel controlId="floatingInput" label="العائلة">
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  placeholder="العائلة"
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.lastName}
                  defaultValue={addressStore.address.lastName}
                  // defaultValue="Otto"
                />
              </FloatingLabel>

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group as={Col} s="2" controlId="validationCustom01">
            {/* <Form.Label>المنطقة</Form.Label> */}
            {/* <FloatingLabel controlId="floatingInput" label="المنطقة">
              <Form.Control
                required
                type="text"
                name="area"
                placeholder="المنطقة"
                // onChange={addressStore.setAddress}

                velue={addressStore.address.area}
                defaultValue={addressStore.address.area}
                readOnly
              />
            </FloatingLabel>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group> */}
            {/* <Form.Group as={Col} md="4" controlId="validationCustomUsername"> */}
            <FloatingLabel controlId="floatingInput" label="المنطقة">
              <Form.Select
                name="area"
                aria-label="Default select example"
                onChange={addressStore.setAddress}
                defaultValue={addressStore.address.area}>
                <option>{addressStore.address.area ?? "اختار المنطقة"}</option>
                {invoiceStore.areas?.map((area) => (
                  <option value={area.area}>{area.area_ar}</option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار المنطقة
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} s="6" controlId="validationCustom03">
              {/* <Form.Label>القطعة</Form.Label> */}

              <FloatingLabel controlId="floatingInput" label="القطعة">
                <Form.Control
                  type="text"
                  placeholder="القطعة"
                  name="block"
                  required
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.block}
                  defaultValue={addressStore.address.block}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار القطعة.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} s="3" controlId="validationCustom04">
              {/* <Form.Label>الجادة</Form.Label> */}

              <FloatingLabel controlId="floatingInput" label="الجادة">
                <Form.Control
                  type="text"
                  placeholder="الجادة"
                  name="ave"
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.ave}
                  defaultValue={addressStore.address.ave}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-3">
            <Form.Group
              as={Col}
              stopPropagation="3"
              controlId="validationCustom05">
              {/* <Form.Label>الشارع</Form.Label> */}

              <FloatingLabel controlId="floatingInput" label="الشارع">
                <Form.Control
                  type="text"
                  placeholder="الشارع"
                  name="street"
                  required
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.street}
                  defaultValue={addressStore.address.street}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار الشارع
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              stopPropagation="3"
              controlId="validationCustom05">
              {/* <Form.Label>المنزل</Form.Label> */}
              <FloatingLabel controlId="floatingInput" label="المنزل">
                <Form.Control
                  type="text"
                  placeholder="المنزل"
                  name="house"
                  required
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.house}
                  defaultValue={addressStore.address.house}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار المنزل
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-3">
            <Form.Group
              as={Col}
              stopPropagation="3"
              controlId="validationCustom05">
              {/* <Form.Label>الشارع</Form.Label> */}

              <FloatingLabel controlId="floatingInput" label="الشارع">
                <Form.Control
                  type="text"
                  placeholder="الشارع"
                  name="Floor"
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.floor}
                  defaultValue={addressStore.address.floor}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار الشارع
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              stopPropagation="3"
              controlId="validationCustom05">
              {/* <Form.Label>المنزل</Form.Label> */}
              <FloatingLabel controlId="floatingInput" label="المنزل">
                <Form.Control
                  type="text"
                  placeholder="المنزل"
                  name="appt"
                  onChange={addressStore.setAddress}
                  velue={addressStore.address.appt}
                  defaultValue={addressStore.address.appt}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                الرجاء اختيار المنزل
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button
            style={{
              width: "100%",
              marginTop: 23,
              backgroundColor: "#c39e81",
              borderColor: "#c39e81",
            }}
            // variant={"outline-danger"}
            onClick={() => addressStore.addAddress(previousPage)}>
            حفظ
          </Button>{" "}
        </Form>
      </div>
    </>
  );
}

export default observer(Address);
