import { observer } from "mobx-react";
import React, { useState } from "react";
import { Form, Button, Col, FloatingLabel } from "react-bootstrap";
import { Redirect } from "react-router";
import authStore from "../stores/authStore";

function ForgetPassword() {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({});
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      authStore.changePass(user);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  if (authStore.user) return <Redirect to="/" />;
  return (
    <div className="profile">
      <p className="title">{"تغيير كلمة السر"}</p>
      <Col md={5} className="auth-container">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* <Row className="mb-3 px-2"> */}
          <FloatingLabel label={"رقم الهاتف"} className="p-1" as={Col}>
            <Form.Control
              type="number"
              name="username"
              placeholder={"phonenumber"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {"fill your phone number"}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel label={"الايميل"} className="p-1" as={Col}>
            <Form.Control
              type="email"
              name="email"
              placeholder={"email"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {"fill your phone number"}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel label={"كلمة السر"} className="p-1" as={Col}>
            <Form.Control
              type="password"
              name="newPassword"
              placeholder={"password"}
              aria-describedby="inputGroupPrepend"
              required
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {"choose password"}
            </Form.Control.Feedback>{" "}
          </FloatingLabel>
          {/* </Row> */}

          {/* <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom04">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" placeholder="State" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label>Zip</Form.Label>
          <Form.Control type="text" placeholder="Zip" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
        </Form.Group>
      </Row> */}
          {/* <Form.Group className="mb-3">
        <Form.Check
          required
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group> */}
          <Form.Group className="p-1">
            <Button
              type="submit"
              className=" w-100"
              style={{ backgroundColor: "#c39e81", borderColor: "#c39e81" }}>
              {"تغير كلة السر"}
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </div>
  );
}

export default observer(ForgetPassword);
